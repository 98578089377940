<template>
  <div>
    <section class="top">
      <div class="container">
        <a href="/#/login" title="立即登录"><i></i><p>立即登录</p></a>
        <div class="logo"><span></span><i></i></div>
      </div>
    </section>
    <section class="main">
      <div class="container">
        <div class="title"><span class="find"></span></div>
        <div class="box">
          <div class="boxname"><span>填写账户名</span></div>
          <div class="boxinfo">
            <div class="row">
              <div class="name">您的账户名<em>*</em></div>
              <div class="inputbox"><input type="text" value="" placeholder="请输入您的账户名" /></div>
              <div class="tips"></div>
            </div>
            <div class="row">
              <div class="name">短信验证码<em>*</em></div>
              <div class="inputbox"><input type="text" value="" placeholder="请输入短信验证码" class="phone" /></div>
              <div class="tips getcode"><a href="javascript:getCode();">发送验证码</a></div>
            </div>
            <div class="row">
              <div class="name">图片验证码<em>*</em></div>
              <div class="inputbox"><input type="text" value="" placeholder="区分大小写" /></div>
              <div class="tips imgcode"></div>
            </div>
            <div class="btns">
              <a href="javascript:submit();" class="submit">确定</a><a href="javascript:history.go(-1);" class="cancel">取消</a>
            </div>
            <div class="notice">温馨提示：输入您的账号名，点击发送验证码，我们会向您账号预留的联系人电话发送短信验证码，输入正确的验证码才可进行重置密码。</div>
          </div>
          <div class="send"><a href="javascript:;" target="_blank" rel="noopener noreferrer">已向您的邮箱发送重置密码链接，立即登录查看</a></div>
        </div>
      </div>
    </section>
    <footerUl></footerUl>
  </div>
</template>

<script>
  export default {
    name: "findPassword",
    data() {
      return {}
    },
    methods: {}
  }
</script>

<style scoped>
  @import "../assets/css/findPassword.css";
</style>
